import React from 'react'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { Color, MoreBar } from '@revolut/ui-kit'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { SendMessage } from '@revolut/icons'

type Props = {
  round?: InterviewRoundInterface
  onOpenSidebar: () => void
}

const UpcomingOnlineTestNotifications = ({ round, onOpenSidebar }: Props) => {
  if (
    round?.latest_interview_stage?.interview_type === 'online_test' &&
    round?.latest_interview_stage?.scheduling_status === 'test_not_sent'
  ) {
    return (
      <ActionWidget
        title={`Test for "${round?.latest_interview_stage.title}" stage is pending`}
        text="Please send the test to the candidate"
        avatarColor={Color.BLUE}
        data-testid="Upcoming online test notification"
      >
        <MoreBar>
          <MoreBar.Action useIcon={SendMessage} onClick={onOpenSidebar}>
            Send test
          </MoreBar.Action>
        </MoreBar>
      </ActionWidget>
    )
  }

  return null
}

export default UpcomingOnlineTestNotifications
